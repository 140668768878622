.form-container {
    padding: 20px;
}

.input-group {
    display: flex;
    flex-wrap: wrap;
    gap: 10px; /* Spaziatura tra i campi */
}

.input-group .MuiTextField-root {
    flex: 1; /* Permette ai campi di occupare spazio disponibile */
    min-width: 150px; /* Larghezza minima per i campi */
}

.date-picker-group {
    display: flex;
    gap: 10px; /* Spaziatura tra i date picker */
    margin-top: 10px;
}
