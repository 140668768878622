/* RoutingRulesManager.css */

.container {
    max-width: 800px; /* Larghezza massima del contenitore */
    margin: 0 auto; /* Centra il contenitore */
    padding: 20px; /* Padding interno */
    background-color: #f9f9f9; /* Colore di sfondo chiaro */
    border-radius: 8px; /* Bordo arrotondato */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Ombra leggera */
}

h2 {
    text-align: center; /* Centra il titolo */
    color: #333; /* Colore del testo */
}

h3 {
    margin-top: 20px; /* Margine superiore per i sottotitoli */
    color: #555; /* Colore del testo per i sottotitoli */
}

input {
    width: calc(100% ); /* Larghezza responsiva con margini */
    padding: 10px; /* Padding interno per gli input */
    margin-bottom: 10px; /* Margine inferiore per separare gli input */
    border: 1px solid #ccc; /* Bordo grigio chiaro */
    border-radius: 4px; /* Bordo arrotondato per gli input */
}

button {
    width: 100%; /* Larghezza completa per i pulsanti */
    padding: 10px; /* Padding interno per i pulsanti */
    background-color: #007bff; /* Colore di sfondo blu */
    color: white; /* Colore del testo bianco */
    border: none; /* Nessun bordo per i pulsanti */
    border-radius: 4px; /* Bordo arrotondato per i pulsanti */
    cursor: pointer; /* Cambia il cursore al passaggio del mouse */
}

button:hover {
    background-color: #0056b3; /* Colore più scuro al passaggio del mouse */
}

ul {
    list-style-type: none; /* Rimuove i punti elenco dalla lista */
    padding: 0; /* Rimuove il padding della lista */
}

li {
    display: flex;
    justify-content: space-between; /* Spazia il contenuto della lista */
    align-items: center; /* Allinea verticalmente gli elementi nella lista */
    padding: 10px;
    margin-bottom: 10px;
    background-color: #fff; /* Colore di sfondo bianco per le righe della lista */
    border-radius: 4px; /* Bordo arrotondato per le righe della lista */
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1); /* Ombra leggera per le righe della lista */
}

li button {
    width: auto; /* Larghezza automatica per il pulsante di rimozione nella lista */
}
/* customSelect.css */
.select-container {
    position: relative; /* Per posizionare il dropdown */
    width: 100%; /* Larghezza completa */
    margin-bottom: 20px; /* Margine inferiore per separare gli elementi */
}

.select {
    width: 100%; /* Larghezza completa */
    padding: 10px; /* Padding interno */
    border: 1px solid #ccc; /* Bordo grigio chiaro */
    border-radius: 4px; /* Bordo arrotondato */
    background-color: #fff; /* Colore di sfondo bianco */
    font-size: 16px; /* Dimensione del font */
    color: #333; /* Colore del testo */
    appearance: none; /* Rimuove lo stile predefinito del browser */
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23333"><path d="M7 10l5 5 5-5z"/></svg>'); /* Freccia verso il basso personalizzata */
    background-repeat: no-repeat; /* Non ripetere l'immagine di sfondo */
    background-position: right 10px center; /* Posiziona l'immagine a destra */
    background-size: 12px; /* Dimensione dell'immagine di sfondo */
    margin-bottom: 10px;
}

.select:hover {
    border-color: #007bff; /* Cambia colore del bordo al passaggio del mouse */
}

.select:focus {
    border-color: #007bff; /* Colore del bordo quando è in focus */
    outline: none; /* Rimuove il contorno predefinito */
    box-shadow: 0 0 5px rgba(0,123,255,0.5); /* Ombra leggera quando è in focus */
}

/* Aggiungi margini superiori e inferiori per uniformità con gli input */
.select-container select {
    margin-top: 10px; /* Margine superiore per allineamento con gli input */
    margin-bottom: 10px; /* Margine inferiore per allineamento con gli input */
}
